<template><div></div></template>

<script>
import API from "../common/api/api.functions";
import loadingMixin from "@/mixins/loadingMixin";

export default {
  name: "ActionLink",
  mixins: [loadingMixin],
  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
    uuidSecond() {
      return this.$route.params.uuid2;
    }
  },
  async mounted() {
    this.loading++;
    let resp = await API.actionLink({
      uuid: this.uuid,
      uuid_second: this.uuidSecond
    })
      .then(res => {
        if (res.data.action_type === 0) {
          this.$toast.success("Вы успешно подтвердили email");
        }
        this.$toast.success("Успешно");
        return res.data;
      })
      .catch(() => {
        this.notFound = true;
      });
    if (resp) {
      if (resp.content.token && resp.content.account) {
        await this.$store.dispatch("obtainToken", {
          token: resp.content.token,
          account: resp.content.account
        });
      }
    }
    this.loading--;
    if (!this.notFound) this.$router.push({ name: "Home" });
  }
};
</script>
